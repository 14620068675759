import React from "react";

export function ErrorPage1() {
  return (
    <div className="d-flex flex-column flex-root">
        <h1
          className="font-size-sm-100 font-weight-boldest text-dark-75 mt-15"
          style={{ fontSize: "150px" }}
        >
          404
        </h1>
        <p className="font-size-h3 font-weight-light">
          OOPS! Something went wrong here
        </p>
    </div>
  );
}
