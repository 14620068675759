/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React from "react";
import BasePage from "./BasePage";

export function Routes() {
    return <BasePage/>
    // return (
    //     <Layout>
    //         {/* 매 페이지 불릴 때마다 불린다고? */}
    //         <BasePage/>
    //     </Layout>
    // );
}
