import styled from "styled-components";
import React, {useEffect, useRef, useState} from "react";
import ReactSlider from "react-slider";
import {Help} from "@material-ui/icons";
import {Popover} from "@material-ui/core";

const commaNum = (number) => {
    if (number) {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
        return "0"
    }
}

const StyledSlider = styled(ReactSlider)`
  width: 100%;
  height: 32px;
  margin-top: 24px;
  margin-bottom: 24px;
  overflow: hidden;
  
  .--circle {
    display: flex;
    align-items: center;
    height: 32px;
    border-radius: 100px;
    padding-left: 10px;
    padding-right: 10px;
    font-weight: 700;
    font-size: 16px;
    background-color: black;
    color: white;
    z-index: unset !important;
    user-select: none;
    white-space: nowrap;
    //top: 0;
  }
  
  .--track {
    top: 14px;
    width: 100%;
    height: 3px;
    background-color: #000000;
    border-radius: 3px;
  }
  
  .--track-1 {
    top: 14px;
    width: 100%;
    height: 3px;
    background-color: #E8E8E8;
    border-radius: 3px;
  }
`;

const 연비 = 10;
const 연간게스트주행거리 = 10000;


const MyPopper = styled.div`
  pointer-events: none;
  background-color: white;
  box-shadow: 0 1px 3px hsla(0,0%,0%,0.06), 0 2px 6px hsla(0, 0%, 0%, 0.06), 0 3px 8px hsla(0, 0%, 0%, 0.09);
  border-radius: 4px;
  padding: 12px;
  margin-top: 8px;
  line-height: 17px;

  > div {
    margin-bottom: 12px;
  }

  > :last-child {
    margin-bottom: 0;
  }
`

const OilButton = () => {
    const guideIcon = useRef();
    const [guide, setGuide] = useState(false);
    return <>
        <Help ref={guideIcon} fontSize="small" onMouseEnter={() => setGuide(true)} onMouseLeave={() => setGuide(false)} />
        <Popover open={guide} anchorEl={guideIcon.current} style={{pointerEvents: "none"}}>
            <div style={{width: "200px", overflow: "hidden", padding: "8px", lineHeight: "22px"}}>
                게스트는 주행한 거리만큼 ‘거리당 주유비’를 납부합니다.<br/>
                대신, 실제로 결제되는 차량의 총 주유비는 호스트가 부담합니다.<br/>
                리터당 기름값을 연비로 나누면 거리당 주유비가 계산됩니다.<br/>
                (예시)<br/>
                - 1리터당 1,800원이고 연비가 10km/l라면 ⇒ 180원/km<br/>
                - 1리터당 1,800원이고 연비가 12km/l라면 ⇒ 150원/km<br/>
                - 1리터당 1,800원이고 연비가 15km/l라면 ⇒ 120원/km<br/>
            </div>
        </Popover>
    </>
}
const RepairButton = () => {
    const guideIcon = useRef();
    const [guide, setGuide] = useState(false);
    return <>
        <Help ref={guideIcon} fontSize="small" onMouseEnter={() => setGuide(true)} onMouseLeave={() => setGuide(false)} />
        <Popover open={guide} anchorEl={guideIcon.current} style={{pointerEvents: "none"}}>
            <div style={{width: "200px", overflow: "hidden", padding: "8px", lineHeight: "22px"}}>
                게스트는 주행한 거리만큼 ‘거리당 정비비’를 납부합니다.<br/>
                엔진오일교환, 타이어교환 등 소모품교체 및 각종 정비에 관한 비용을 모두 포함합니다.<br/>
                (예시) 연간 정비비가 300만원이고 총 주행거리가 2만km라면 ⇒ 150원/km
            </div>
        </Popover>
    </>
}

//
const ProfitCalculator = ({form, onChange}) => {
    const guideIcon = useRef();
    const [guide, setGuide] = useState(false);

    useEffect(() => {
        // onChange차량시세(form.차량시세);
        // onChange보험세금(form.보험세금);
        // onChange예상거리당비용(form.예상거리당비용);
        form.차량가격 = 5000
        form.본인연봉 = 5000
        onChange차량가격(form.차량가격)
        onChange본인연봉(form.본인연봉)
    }, []);

    const 감가분담액 = () => {
        return form.차량시세 * 0.15 / 2;
    }

    const 보험세금분담액 = () => {
        return form.보험세금 / 2;
    }

    const 연간거리당비용 = () => {
        return form.예상거리당비용 * 연간게스트주행거리;
    }

    const onChange차량시세 = (v) => {
        onChange({id: "차량시세", value: v});
    }

    const onChange보험세금= (v) => {
        onChange({id: "보험세금", value: v});
    }

    const onChange예상거리당비용 = (v) => {
        onChange({id: "예상거리당비용", value: v});
    }


    // 출차를 위한 함수들
    const onChange차량가격 = (v) => {
        onChange({id: "차량가격", value: v});
    }

    const onChange본인연봉 = (v) => {
        onChange({id: "본인연봉", value: v});
    }

    const 소득세구간 = () => {
        const 사회보험=7.5
        if(form.본인연봉 >= 15000) {
            return (38.0 * 1.1 + 사회보험).toFixed(1)
        } else if(form.본인연봉 >= 8800) {
            return (35.0 * 1.1 + 사회보험).toFixed(1)
        } else if(form.본인연봉 >= 4600) {
            return (24.0 * 1.1 + 사회보험).toFixed(1)
        } else if(form.본인연봉 >= 1200) {
            return (15.0 * 1.1 + 사회보험).toFixed(1)
        }
        return (6.0 * 1.1 + 사회보험).toFixed(1)
    }

    // 연봉에서 연간비용 부분이 상단에 위치
    // 기준 컷으로 비용을 자르고, 세금 부분을 계산한다
    const 연간절약액 = () => {
        const 연간비용 = form.차량가격 / 5
        var 연간절약액 = 0
        const 비용시작 = form.본인연봉 - 연간비용
        var 비용끝 = form.본인연봉

        // 연봉 구간계산, 12~46:15%, ~88:24%, ~150:35%, ~300:38%
        // 지방세 *1.1
        // 4대 보험은 7.5%로 계산
        if(비용끝 > 15000) {
            var 해당비용 = 비용끝 - 15000
            if(비용시작 > 15000) {
                해당비용 -= (비용시작 - 15000)
                비용끝 = 0
            } else {
                비용끝 = 15000
            }
            연간절약액 += 해당비용 * (0.38*1.1 + 0.075)
        }
        if(비용끝 > 8800) {
            var 해당비용 = 비용끝 - 8800
            if(비용시작 > 8800) {
                해당비용 -= (비용시작 - 8800)
                비용끝 = 0
            } else {
                비용끝 = 8800
            }
            연간절약액 += 해당비용 * (0.35*1.1 + 0.075)
        }
        if(비용끝 > 4600) {
            var 해당비용 = 비용끝 - 4600
            if(비용시작 > 4600) {
                해당비용 -= (비용시작 - 4600)
                비용끝 = 0
            } else {
                비용끝 = 4600
            }
            연간절약액 += 해당비용 * (0.24*1.1 + 0.075)
        }
        if(비용끝 > 1200) {
            // 연봉이 46백만원 이하다
            var 해당비용 = 비용끝 - 1200
            if(비용시작 > 1200) {
                해당비용 -= (비용시작 - 1200)
                비용끝 = 0
            } else {
                비용끝 = 1200
            }
            연간절약액 += 해당비용 * (0.15*1.1 + 0.075)
        }

        return 연간절약액.toFixed(0)
    }



    return <_ProfitCalculator>
        <div className="wrap">
            <div className="top-box">
                <div className="profit">
                    총 절약액 : <span className="pink">₩ {commaNum(연간절약액()*5)}만원</span>
                </div>
                <div className="desc">
                    비용처리로 인한 소득세 + 지방세 + 4대보험 감소분
                </div>
            </div>
            <div className="slider-box">
                <div className="title">
                    원하는 차의 가격을 입력해보세요
                </div>
                <StyledSlider
                    className="horizontal-slider"
                    thumbClassName="--circle"
                    trackClassName="--track"
                    defaultValue={5000}
                    step={100}
                    min={1000}
                    max={7500}
                    onChange={onChange차량가격}
                    renderThumb={(props, state) => <div {...props}>{commaNum(state.valueNow)}만원</div>}
                />
                <div className="desc">
                    <span>
                        비용인정액<Help ref={guideIcon} fontSize="small" onMouseEnter={() => setGuide(true)} onMouseLeave={() => setGuide(false)} />
                    <Popover open={guide} anchorEl={guideIcon.current} style={{pointerEvents: "none"}}>
                        <div style={{width: "200px", overflow: "hidden", padding: "8px", lineHeight: "22px"}}>
                            연간 비용으로 인정되는 금액입니다<br/>
                            운행기록부 미작성 시 인정되는 금액은 연간 1500만원이 한도입니다<br/>
                            렌트비는 1140만원까지 인정되며 나머지는 주유쿠폰으로 지급해드립니다
                        </div>
                    </Popover> : {commaNum(form.차량가격 / 5)}만원/년
                    </span>
                </div>
            </div>

            <div className="slider-box">
                <div className="title">
                    본인의 세전 연봉을 입력해보세요
                </div>
                <StyledSlider
                    thumbClassName="--circle"
                    trackClassName="--track"
                    defaultValue={5000}
                    step={100}
                    min={2000}
                    max={20000}
                    onChange={onChange본인연봉}
                    renderThumb={(props, state) => <div {...props}>{commaNum(state.valueNow)}만원</div>}
                />
                <div className="desc">
                    <span>
                        연봉 상단의 세율 : {commaNum(소득세구간())}% (소득세, 지방세, 4대보험 포함)
                    </span>
                </div>
            </div>

            {/*<div className="slider-box">*/}
            {/*    <div className="title">*/}
            {/*        게스트가 1km를 주행할 때마다 받을 금액은 얼마인가요?*/}
            {/*    </div>*/}
            {/*    <p>* 주유비<OilButton/>, 정비비<RepairButton/> 합산시 보통 280원 정도입니다.</p>*/}
            {/*    <StyledSlider*/}
            {/*        thumbClassName="--circle"*/}
            {/*        trackClassName="--track"*/}
            {/*        defaultValue={280}*/}
            {/*        step={5}*/}
            {/*        min={0}*/}
            {/*        max={600}*/}
            {/*        onChange={onChange예상거리당비용}*/}
            {/*        renderThumb={(props, state) => <div {...props}>{commaNum(state.valueNow)}원</div>}*/}
            {/*    />*/}
            {/*</div>*/}

            {/*<div className="bottom-box">*/}
            {/*    <div className="profit">*/}
            {/*        연간절약액 : <span className="pink">₩ {commaNum(연간절약액())}만원/년</span>*/}
            {/*    </div>*/}
            {/*    <div className="profit">*/}
            {/*        실수령기준 구매액 : <span className="pink">₩ {commaNum(form.차량가격 - 연간절약액()*5)}만원</span> (원래가격: {commaNum(form.차량가격)}만원)*/}
            {/*    </div>*/}
            {/*    <div className="profit">*/}
            {/*        5년간 절약액 : <span className="pink">₩ {commaNum(연간절약액()*5)}만원</span> (할인율 : {commaNum((100*연간절약액()*5/form.차량가격).toFixed(1))}%)*/}
            {/*    </div>*/}
            {/*    <div className="profit">*/}
            {/*        할인율 : <span className="pink">{commaNum((100*연간절약액()*5/form.차량가격).toFixed(1))}%</span>*/}
            {/*    </div>*/}
            {/*    /!*<div className="desc">(평균 연비 10km/l, 게스트가 한달에 15일씩 1년간 1만km 주행 가정)</div>*!/*/}
            {/*</div>*/}

            <div className="bottom-box">
                <div className="profit">
                    실수령기준 차량 구매액 : ₩ {commaNum(form.차량가격 - 연간절약액()*5)}만원 (원래 차량가격: {commaNum(form.차량가격)}만원)
                    [<span className="pink">할인율 : {commaNum((100*연간절약액()*5/form.차량가격).toFixed(1))}%</span>]
                </div>
                <div className="profit">
                    매년 {commaNum(연간절약액())}만원씩, 5년간 총 <span className="pink">{commaNum(연간절약액()*5)}만원</span>을 아낄 수 있어요
                </div>

                {/*<div className="desc">(평균 연비 10km/l, 게스트가 한달에 15일씩 1년간 1만km 주행 가정)</div>*/}
            </div>


            {/*<p className="h3">예상수익: 총 {commaNum(감가분담액() + 보험세금분담액() + (연간거리당비용() / 10000))}만원/년</p>*/}
        </div>
    </_ProfitCalculator>
}


const _ProfitCalculator = styled.div`
  > .wrap {
    padding: 20px;
    //background: #FCFCFC;
    //border-width: 1px;
    //border-style: solid;
    //border-color: #D9D9D9;
    
    > .top-box {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: #F7F7F7;
      border-radius: 8px;
      padding: 16px 0;
      margin-bottom: 36px;
      > .profit {
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 12px;
        > .pink {
          font-weight: 800;
          color: #FF8070;
        }
      }
      > .desc {
        font-size: 14px;
        font-weight: 400;
        color: #A9A9A9;
      }
    }
    
    > .slider-box {
      margin-bottom: 48px;
      > .title {
        font-size: 21px;
        font-weight: 700;
        line-height: 150%;
      }
      
      > p {
        font-size: 14px;
        font-weight: 500;
        color: #A9A9A9;
        margin-top: 8px;
      }
      
      > .desc {
        display: flex;
        
        > span {
          padding: 8px;
          background-color: #F5F5F5;
          border-radius: 4px;
          font-size: 14px;
          font-weight: 700;
          color: #6D6D6D;
        }
      }
    }


    > .bottom-box {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      padding: 20px 0;
      > .profit {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 8px;
        > .pink {
          font-weight: 800;
          color: #FF8070;
        }
      }
      > .desc {
        font-size: 12px;
        font-weight: 400;
        color: #A9A9A9;
      }
    }
  }
`;

export default ProfitCalculator;
