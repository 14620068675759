import {forEach, isArray} from "lodash";
import {useCallback, useState} from "react";

const {TelegramClient} = require('messaging-api-telegram');

const client = new TelegramClient({
    accessToken: '1393058794:AAHRCsMtABS5vz-kJbml5zw3EKjbTGm6joc',
});

const CHAT_IDs = [
    '333017874', // 재창님
    '1334101397', // Taylor?
    '2022881962', // 윤석님
    '1753870954', // 성환님
    '5013987283', // 현식님
];

export const sendTelegram = (payload) => {
    CHAT_IDs.map(async (CHAT_ID) => {
        await client.sendMessage(CHAT_ID, payload, {
            disableWebPagePreview: true,
            disableNotification: true,
        });
    });
}

export const test = () => {
    CHAT_IDs.map(async (CHAT_ID) => {
        console.log(await client.getUpdates());
    });
}

export const loadKakaoSdk = () => {
    return new Promise((resolve, reject) => {
        const js = document.createElement("script");

        js.id = "kakao-sdk";
        js.src = "//developers.kakao.com/sdk/js/kakao.min.js";
        js.onload = resolve;

        document.body.append(js);
    });
};

export const openKakaoChat = () => {
    window.open('http://pf.kakao.com/_PRxfPb/chat', '_blank', 'toolbar=no,width=400,height=400');
    // window.Kakao.Channel.chat({channelPublicId: '_PRxfPb'});
}


export const useForm = (defaultForm = {}) => {
    const [form, setForm] = useState({...defaultForm});

    const onChange = useCallback((input) => {
        if (isArray(input)) {
            setForm(old => {
                let newValues = {...old}
                forEach(input, ({id, value}) => {
                    newValues[id] = value
                })
                return newValues
            })
        }//
        else {
            setForm(old => ({...old, [input.id]: input.value}));
        }
    }, []);

    const resetValue = useCallback((id) => {
        setForm(old => ({...old, [id]: undefined}));
    }, []);

    const reset = useCallback(() => {
        setForm({});
    }, []);

    return {form, onChange, resetValue, reset};
};
