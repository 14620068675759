import React, {useEffect, useState} from "react";
import "../css/default.css";
import {openKakaoChat, sendTelegram, useForm} from "../my/func";
import ProfitCalculator from "./ProfitCalculator";

export const MainPage = ({location, history}) => {
    const [open, setOpen] = useState(false);
    const [company, setCompany] = useState("");
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [memo, setMemo] = useState("");
    const [agreement, setAgreement] = useState(false);

    const [isMobile, setMobile] = useState(window.innerWidth < 1165);
    const [scroll, setScroll] = useState(window.scrollY);
    const { form: calculatorForm, onChange: calculatorChange } = useForm({
        차량시세: 2500,
        보험세금: 120,
        예상거리당비용: 280,
        // 정비비: 300,
        // 리터당주유비: 1700,
    });

    const handleResize = () => {
        setMobile(window.innerWidth < 1165);
    };

    const handleScroll = () => {
        setScroll(window.scrollY);
    };

    const handleCompany = (event) => {
        setCompany(event.target.value);
    }

    const handleName = (event) => {
        setName(event.target.value);
    }

    const handleEmail = (event) => {
        setEmail(event.target.value);
    }

    const handlePhone = (event) => {
        setPhone(event.target.value);
    }

    const handleMemo = (event) => {
        setMemo(event.target.value);
    }

    const handleAgreement = (event) => {
        setAgreement(!agreement);
    }

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, []);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        }
    }, []);

    return <>
        <div className="bg_dimmed"/>
        <div id="wrap">
    <header id="header" className={`${scroll > 746 ? 'sticky' : ''}`}>
        <div className="inner_cont">
            <h1 className="logo"><a href="javascript:void(0);"><img src={`/media/chulcha/img/logo_${scroll > 746 ? 'b' : 'w'}.svg`} alt="출차" id="logo"/></a></h1>
            <div className="pc_f">
                <a href="javascript:void(0);" className="btn_download" onClick={() => window.open('/media/chulcha/[출차]서비스소개_230822.pdf', '_blank')}>출차소개서(법인용)</a>
                {/*<a href="javascript:void(0);" className="btn_apply" onClick={() => setOpen(true)}>기업 상담 신청</a>*/}
                <a href="javascript:void(0);" className="btn_apply" onClick={() => openKakaoChat()}>상담 신청</a>
            </div>
            <div className="m_f">
                {/*<a href="javascript:void(0);" className={`btn_download ${scroll > 0 ? 'pc_f' : 'm_f'}`} id="mobileDownload" onClick={() => window.open('/media/chulcha/출차서비스상품소개서_2021-11-26.pdf', '_blank')}>출차소개서(법인용)</a>*/}
                <a href="javascript:void(0);" className={`btn_download ${scroll > 0 ? 'pc_f' : 'm_f'}`} id="mobileDownload" onClick={() => window.open('/media/chulcha/[출차]서비스소개_230822.pdf', '_blank')}>출차소개서(법인용)</a>
                {/*<a href="javascript:void(0);" className={`btn_apply m_f${scroll > 0 ? ' fix': ''}`} id="mobileApply" onClick={() => setOpen(true)}>기업 상담 신청</a>*/}
                <a href="javascript:void(0);" className={`btn_apply m_f${scroll > 0 ? ' fix': ''}`} id="mobileApply" onClick={() => openKakaoChat()}>상담 신청</a>
            </div>
        </div>
    </header>
    <div className="container">
        <section className="main_top">
            <div className="video_wrap pc_br">
                <img src="/media/chulcha/img/pc_banner.png"/>
                {/*<video muted preload="metadata" loop autoPlay playsInline src="/media/chulcha/movie/test.mp4"/>*/}
            </div>
            <div className="video_wrap m_br">
                <img src="/media/chulcha/img/mobile_banner.png"/>
                {/*<video muted preload="metadata" loop autoPlay playsInline src="/media/chulcha/movie/test_m.mp4"/>*/}
            </div>
            <div className="text_box">
                <p className="pc_br">
                    직장인이라면? 차 그냥 사지마세요<br className="pc_br"/>
                    출차로 비용처리하면<br className="pc_br"/>
                    원하는 차를 최대 40% 이상! 저렴하게 탈 수 있습니다
                </p>
                <p className="m_br">
                    {/*직장인이<br className="m_br"/>연봉 인상보다 더 만족하는<br className="m_br"/>월 단위 업무용 차량 구독 서비스*/}
                    직장인이라면? 차 그냥 사지마세요<br className="m_br"/>
                    출차로 비용처리하면<br className="m_br"/>
                    원하는 차를 최대 40% 이상! <br className="m_br"/>
                    저렴하게 탈 수 있습니다
                </p>
            </div>
            <div className="txt">출차</div>
        </section>

        <section>
            <ProfitCalculator form={calculatorForm} onChange={calculatorChange}/>
        </section>

        <section className="cont_2">
            <div className="inner_cont">
                <p className="pc_br">
                    원하는 차 찾기 <br/>
                    신차, 중고차 중<br/>
                    골라보기
                </p>
                <p className="m_br">
                    원하는 차 찾기 <br/>
                    신차, 중고차 중<br/>
                    골라보기
                </p>
                <a href="javascript:void(0);" className="btn_apply" onClick={() => window.open('https://www.lotterentacar.net/hp/kor/sinchajang/estimate.do')}>신차</a> &nbsp;&nbsp;
                <a href="javascript:void(0);" className="btn_apply" onClick={() => window.open('https://biz.bakcha.com/pages#/product')}>중고차</a> &nbsp;&nbsp;
                <a href="javascript:void(0);" className="btn_apply" onClick={() => openKakaoChat()}>문의하기</a>
            </div>
            <div className="inner_cont">
                <p>
                    해당 사이트가 아니라 링크를 가지고 상담 문의 주시면 상담해드리겠습니다
                </p>
            </div>

        </section>

        <section className="cont_3">
            <div className="inner_cont">
                <h2>회사와 직장인<br/> 모두 Win</h2>
                <div className="box">
                    <div className="text_box">
                        <ul>
                            <li>
                                <b>2015년, 세법이 개정되면서 출퇴근용 차량의 비용인정이 적법하게 되었습니다</b><br/>
                                그런데, 여전히 일부 임원들의 전유물로 취급되고 있는 것이 현실입니다. 반면 독일 등에서는 촐퇴근 차량의 비용처리가 일반적 입니다.<br/>
                                출차는 새로운 보상 체계를 위한 문화를 지원하겠습니다
                            </li>
                            <li>
                                <b>직장인의 소득세는 과세표준이 커질수록 세율이 누진적으로 증가합니다.</b><br/>
                                직장인은 연봉이 오를 수록 소득세, 지방세, 4대보험 등으로 인하여 실질적인 만족을 얻기 어려운 것이 현실이죠<br/>

                            </li>
                            <li>
                                <b>연봉 인상 대신 출차를 선택해보세요</b><br/>
                                직원은 연봉 인상 대신 실질적인 출퇴근 차를 제공 받고,<br/>
                                회사는 다양한 보상체계 중 하나로 직원에게 만족감을 줄 수 있습니다.
                            </li>
                        </ul>
                        {/*<p>*/}
                        {/*    그 밖의 구독 후 발생되는 다양한 차량 운영 리스크*/}
                        {/*    관리를 출차가 맡기 때문에, 안심하고 직원에게 업무용*/}
                        {/*    차량을 제공할 수 있습니다.*/}
                        {/*</p>*/}
                    </div>
                    <img src="/media/chulcha/img/img_car_01.png" alt=""/>
                </div>
            </div>
        </section>

        <section className="cont_1">
            <p className="tit">
                왜 <b>출차로 시작</b>해야 할까요? <br/>
                {/*시작하는 <b>직원과 회사 모두 WIN</b>*/}
            </p>
            <ul>
                <li>
                    <img src="/media/chulcha/img/info_img_01.png" alt=""/>
                    <p className="t1">자유로운 차량 선택</p>
                    <p className="t2">국산차/외제차, 신차/중고차 제한 없이 직원 본인이 원하는 차량으로 진행할 수 있습니다 <br className="pc_br"/> </p>
                </li>
                <li>
                    <img src="/media/chulcha/img/info_img_02.png" alt=""/>
                    <p className="t1">중도 반납 가능</p>
                    <p className="t2">직원에게 차를 제공했는데, 퇴사하면 어떻게 처리해야할지 고민되시죠?<br className="pc_br"/>퇴사 시 차량 반납이 가능하므로, 회사의 리스크가 없습니다</p>
                </li>
                <li>
                    <img src="/media/chulcha/img/info_img_03.png" alt=""/>
                    <p className="t1">서류처리는 출차가 알아서</p>
                    <p className="t2">출차의 세무사가 차량비용처리절차 등 모든 서류처리를 처음부터 끝까지 가이드해드립니다<br className="pc_br"/>회사와 직원 모두 신경쓸 필요가 전혀 없습니다</p>
                </li>
                <li>
                    <img src="/media/chulcha/img/info_img_04.png" alt=""/>
                    <p className="t1">출차만의 케어서비스</p>
                    <p className="t2">출차의 협력업체에서 합리적인 가격으로 차량 유지/정비 서비스를 제공해드리며<br className="pc_br"/>
                        사고시 저렴한 가격으로 대차 서비스를 제공해드립니다</p>
                </li>
            </ul>
        </section>

        <section className="cont_4">
            <div className="inner_cont">
                <h2>출차 서비스 고객 리뷰</h2>
                <ul className="service_list">
                    <li>
                        <p className="t1">지원자들이 한 번 더 묻더라고요</p>
                        <p className="t2">
                            동종업계에서 모두 비슷한 연봉을 제안받는 지원자들에게
                            타 기업에는 없는 차량 지원 혜택을 제안하니
                            다들 놀라면서 한 번 더 자세한 내용을 묻는 것을 보고
                            정말 매력적인 제안이란 생각을 했습니다.
                        </p>
                        <div className="user_box">
                            <img src="/media/chulcha/img/img_user_01.png" alt=""/>
                            <div>
                                <p className="p1">한 ** 고객님</p>
                                <p className="p2">판교 게임 스타트업 채용팀</p>
                            </div>
                        </div>
                    </li>
                    <li>
                        <p className="t1">이젠 한눈팔지 않는 것 같아요</p>
                        <p className="t2">
                            어학원 간의 경쟁이 심하다 보니 소위 1타 강사의 이직을
                            막는 것이 항상 저의 고민이었어요. 연봉을 업계 최고로
                            계속 유지하는 건 어렵지만 출차로 차를 제공했더니 이젠
                            쉽게 이직할 생각을 안 하는 것 같아 다행이에요.
                        </p>
                        <div className="user_box">
                            <img src="/media/chulcha/img/img_user_02.png" alt=""/>
                            <div>
                                <p className="p1">송 ** 고객님</p>
                                <p className="p2">강남 영어 회화 어학원 원장</p>
                            </div>
                        </div>
                    </li>
                    <li>
                        <p className="t1">연봉 인상보다 오히려 더 좋아해요</p>
                        <p className="t2">
                            실력을 인정해 주고, 그만큼 대우해 주는 것이 회사에
                            대한 로열티에 중요했는데, 금전적인 보상 이외에 출차
                            혜택을 제공하니 주변에 자랑할 수 있어 뿌듯해하더
                            라고요. 보이지 않는 연봉 인상보다 오히려 더 좋아해요.
                        </p>
                        <div className="user_box">
                            <img src="/media/chulcha/img/img_user_03.png" alt=""/>
                            <div>
                                <p className="p1">신 ** 고객님</p>
                                <p className="p2">법무법인 대표변호사</p>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </section>

        <section className="cont_5">
            <div className="inner_cont">
                <h2>출차 서비스 이용방법</h2>
                <ol className="service_step">
                    <li>
                        <p className="t1">STEP 01</p>
                        <p className="t2">출차 서비스 이용 상담</p>
                        <p className="t3">상단 ‘기업 상담 신청'을 <br/>통한 상담 접수</p>
                    </li>
                    <li>
                        <p className="t1">STEP 02</p>
                        <p className="t2">차량 선택 및 계약 체결</p>
                        <p className="t3">희망 차량 및 출차 시기 <br/>협의 후 계약</p>
                    </li>
                    <li>
                        <p className="t1">STEP 03</p>
                        <p className="t2">차량 인도 및 임직원 이용하기</p>
                        <p className="t3">기업으로 차량 탁송 후 <br/>실 차량 이용자에게 차량 인도</p>
                    </li>
                    <li>
                        <p className="t1">STEP 04</p>
                        <p className="t2">차량 이용내역 확인 및 정산</p>
                        <p className="t3">매달 말 전월 차량 이용내역 <br/>발송 후 구독료 정산 처리</p>
                    </li>
                </ol>
            </div>
        </section>

        <section className="cont_6">
            <div className="inner_cont">
                <p>
                    직장인이 연봉 인상보다 더 만족하는<br/>
                    업무용 차량 구독 서비스<br/>
                    바로 상담받으세요.
                </p>
                <div className="btn_box">
                    {/*<a href="javascript:void(0);" className="btn_apply" onClick={() => setOpen(true)}>기업 상담 신청</a>*/}
                    <a href="javascript:void(0);" className="btn_apply" onClick={() => openKakaoChat()}>기업 상담 신청</a>
                    <a href="javascript:void(0);" className="btn_download" onClick={() => window.open('/media/chulcha/[출차]서비스소개_230822.pdf', '_blank')}>출차 상품 소개서</a>
                </div>
            </div>
        </section>
    </div>
    <footer>
        <div className="inner_cont">
            <p className="cop_name">(주) 원투유니콘</p>
            <p className="txt">
                <span>대표 : 허재창</span>
                <span>서울특별시 서초구 강남대로 53길 8 마이워크스페이스타워 9층</span>
                <span>정보보호최고책임자 : 허재창</span>
            </p>
            <p className="txt">
                <span>기업 상담 : contact@chulcha.com</span>
                <span>H.P : 010-2414-3743</span>
            </p>
            <p className="txt2">사업자 등록번호 : 544-87-01418</p>
        </div>
    </footer>

    <section className={`layer_pop${open ? ' active': ''}`}>
        <div className="layer_cont">
            <p className="t1">출차 기업 상담 신청</p>
            <p className="t2">
                아래 내용을 작성해 주시면 최대한 빠른 시일 내에
                연락드리겠습니다. 감사합니다.
            </p>
            <p className="tit">기업명</p>
            <input type="text" value={company} onChange={handleCompany} className="input" placeholder="상담하는 기업명을 입력해 주세요."/>
            <p className="tit">담당자 성함</p>
            <input type="text" value={name} onChange={handleName} className="input" placeholder="연락받을 담당자 성함을 입력해 주세요."/>
            <p className="tit">담당자 이메일 주소</p>
            <input type="text" value={email} onChange={handleEmail} className="input" placeholder="연락받을 담당자 이메일 주소를 입력해주세요."/>
            <p className="tit">담당자 연락처</p>
            <input type="text" value={phone} onChange={handlePhone} className="input" placeholder="연락받을 번호를 입력해주세요."/>
            <p className="tit">상담 희망 내용</p>
            <textarea value={memo} onChange={handleMemo} name="" id="" cols="30" rows="10" className="input" placeholder="상담을 원하는 내용을 간략히 작성해 주세요. (500자 이내)"/>
            <label>
                <input checked={agreement} onChange={handleAgreement} type="checkbox" className="check"/>
                <span>개인정보 수집 및 이용약관에 동의합다. <b>(필수)</b></span>
            </label>
            <button type="button" className="btn_apply" onClick={() => {
                if (!agreement) {
                    alert("필수 약관에 동의해주셔야합니다.");
                } else {
                    const payload = {
                        "기업명": company,
                        "담당자 성함": name,
                        "담당자 이메일 주소": email,
                        "담당자 연락처": phone,
                        "상담 희망 내용": memo,
                    }
                    alert("상담 신청이 완료되었습니다.")
                    sendTelegram("출차상담신청 : " + JSON.stringify(payload, null, 2))
                    setOpen(false);
                }
            }}>기업 상담 신청</button>
            <button className="btn_closed" onClick={() => setOpen(false)}><img src="/media/chulcha/img/btn_closed.svg" alt="닫기"/></button>
        </div>
    </section>
</div>
    </>;
};
