import {SERVER_ADDRESS} from "../app/App";

const exceptApi = ['/auth'];

export default function setupAxios(axios, store) {
    axios.interceptors.request.use(
        config => {
            const {
                auth: {authToken}
            } = store.getState();

            // 우리 API 서버 호출시에만 authToken을 넣어준다. 예외 API의 경우에는 넣지 않는다. --blackcat
            if (authToken && config.url.startsWith(SERVER_ADDRESS)) {
                const isExcept = exceptApi.reduce((acc, current) =>
                    acc + config.url.startsWith(SERVER_ADDRESS + current) ? 1 : 0, 0);
                if (!isExcept) {
                    config.headers.Authorization = `Bearer ${authToken}`;
                }
            }

            return config;
        },
        err => Promise.reject(err)
    );
}
