/**
 * Entry application component used to compose providers and render Routes.
 * */

import React from "react";
import {Provider} from "react-redux";
import {BrowserRouter} from "react-router-dom";
import {PersistGate} from "redux-persist/integration/react";
import {Routes} from "./Routes";
import {setContext} from '@apollo/client/link/context';

// 통신용 apollo 관련
import ApolloClient from "apollo-client"
import {InMemoryCache} from "apollo-cache-inmemory"
import {createHttpLink} from "apollo-link-http"
import {ApolloProvider, from} from "@apollo/react-hooks"
import {Spinner} from "react-bootstrap";

// 서버 주소
export const SERVER_ADDRESS = 'https://dry-river-37620.herokuapp.com'

// const authLink = setContext((_, {headers}) => {
//   const token = getAuthToken(3600);
//   return {
//     headers: {
//       ...headers,
//       authorization: token ? `Bearer ${token}` : '',
//     },
//   };
// });

 const httpLink = createHttpLink({ uri: SERVER_ADDRESS+'/graphql' });
 const client = new ApolloClient({
   link: httpLink,
   cache: new InMemoryCache(),
 });

export default function App({ store, persistor, basename }) {
  return (
    <ApolloProvider client={client}>
      {/* Provide Redux store */}
      <Provider store={store}>
        {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
        <PersistGate persistor={persistor}>
          {/* Add high level `Suspense` in case if was not handled inside the React tree. */}
          <React.Suspense fallback={Spinner}>
            {/* Override `basename` (e.g: `homepage` in `package.json`) */}
            <BrowserRouter basename={basename}>
              {/*This library only returns the location that has been active before the recent location change in the current window lifetime.*/}
              {/*<MaterialThemeProvider>*/}
                {/* Provide `react-intl` context synchronized with Redux state.  */}
                  {/* Render routes with provided `Layout`. */}
                  <Routes />
              {/*</MaterialThemeProvider>*/}
            </BrowserRouter>
          </React.Suspense>
        </PersistGate>
      </Provider>
    </ApolloProvider>
  );
}
